import React from 'react';

function Features() {
  return (
    <div className="container py-5">

        <div className="row mt-5 text-center">
            <p className="preText text-center">Features</p>
            <h2 class="fw-bold">Why chose Demmypay?</h2>
            <p className='lead mt-3 fs-5'>
                Experience the ultimate convenience in airtime and data vending.<br></br> With features designed to serve individuals, businesses, and vendors. <br></br>DemmyPay delivers unmatched value and reliability every time
            </p>
        </div>

      <div className="row mt-5">
        <div className="col-md-4 text-center mb-5">
            <div className='mb-3'>
                <i className="bi bi-lightning-fill fs-3 mb-3 px-3 py-2 border border-1 rounded shadow-md"></i>
            </div>
          <h5 className='fw-bold'>Fast transactions</h5>
          <p>Instant airtime and data delivery.</p>
        </div>
        <div className="col-md-4 text-center mb-5">
            <div className='mb-3'>
                <i className="bi bi-emoji-laughing-fill fs-3 mb-3 px-3 py-2 border border-1 rounded shadow-md"></i>
            </div>
          <h5 className='fw-bold'>Best Rates</h5>
          <p>Affordable bundles for everyone.</p>
        </div>
        <div className="col-md-4 text-center mb-5">
            <div className='mb-3'>
                <i className="bi bi-shield-fill-check fs-3 mb-3 px-3 py-2 border border-1 rounded shadow-md"></i>
            </div>
          <h5 className='fw-bold'>Security</h5>
          <p>Security is guaranteed on every move.</p>
        </div>
        <div className="col-md-4 text-center mb-5">
            <div className='mb-3'>
                <b className="bi bi-gear-wide-connected fs-3 mb-3 px-3 py-2 border border-1 rounded shadow-md"></b>
            </div>
          <h5 className='fw-bold'>API Integration</h5>
          <p>Build your platform with ease.</p>
        </div>
        <div className="col-md-4 text-center mb-5">
            <div className='mb-3'>
                <b className="bi bi-telephone-inbound-fill fs-3 mb-3 px-3 py-2 border border-1 rounded shadow-md"></b>
            </div>
          <h5 className='fw-bold'>24/7 Support</h5>
          <p>We're here for you anytime, any day.</p>
        </div>
      </div>
      
    </div>
  );
}

export default Features;

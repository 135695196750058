import React from "react";

var img = [
    ["MTN","https://demmyglobal.com/images/65afccf951a3a6cd3d9ecc6b_Frame%20%282%29.svg"],
    ["Airtel","https://demmyglobal.com/images/65afcc7917b769d1c90a16b8_Frame%20336.svg"],
    ["Globacom","https://demmyglobal.com/images/65afcd32af4532ecf2d82fd8_Frame%20%283%29.svg"],
    ["9mobile","https://www.cysend.com/images/products/9mobile.svg"]
];

function Companies(){
    return(
        <div className="container text-center mt-2 mb-5 border-top border-1 text-dark">
            <p className="lead mt-3 fs-5 pt-3 fw-bold">Trusted by top industry leaders</p>
            <div className="row mt-5">
                <div className='col-12 mx-auto'>
                    <div className='row partnersScroll d-flex' style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                        {
                            img.map((item, index) => {
                                return(
                                    <div className='col-3' key={index} style={{ display: 'inline-block', float: 'none' }}>
                                        <div className='card px-1 py-3 partnerCard mb-4'>
                                            <div>
                                                <img src={item[1]} alt={item[0]} key={index} style={{width:"70px", height:"60px"}}></img>
                                            </div>
                                            <div className='mt-2'>
                                                <b>{item[0]}</b>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Companies;
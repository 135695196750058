import React from 'react';

var imgPeople = './img/people.png';
var imgVendors = './img/people.png';
var imgBusinesses = './img/people.png';

function FeaturesCards(){
    return(
        <div className="row mt-5 bg-primary-app py-5" id='feature'>
            <div className='container py-5'>
                <p className="preText text-center">Products</p>

                <h2 class="fw-bold text-white text-center">An all-in-one platform</h2>
                <p className='lead mt-3 fs-5 text-white text-center'>We service everyone</p>
                <div className="row mt-3">
                    <div className='col-lg-4 col-md-6 col-sm-10 mx-auto'>
                        {/* <!-- Nav tabs --> */}
                        <ul className="nav nav-pills bg-white row py-2 px-1" id="myTab" role="tablist">
                            <li className="nav-item col-4" role="presentation">
                                <button
                                    className="nav-link active w-100"
                                    id="home-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#home"
                                    type="button"
                                    role="tab"
                                    aria-controls="home"
                                    aria-selected="true"
                                >
                                    Buy and sell
                                </button>
                            </li>
                            <li className="nav-item col-4" role="presentation">
                                <button
                                    className="nav-link w-100"
                                    id="profile-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="profile"
                                    aria-selected="false"
                                >
                                    Mega share
                                </button>
                            </li>
                            <li className="nav-item col-4" role="presentation">
                                <button
                                    className="nav-link w-100"
                                    id="messages-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#messages"
                                    type="button"
                                    role="tab"
                                    aria-controls="messages"
                                    aria-selected="false"
                                >
                                    APIs
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="container mt-5 tab-content py-3 ">

                    <div className="tab-pane active row"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab">
                            <div className="col-lg-6 col-md-6 sm-12 xs-12  p-3 float-start text-white p-5 ">
                                <h1 className='display-4 fw-bolder'>Seamless Airtime & Data Vending for everyone!</h1>
                                <p className='lead mt-3 fs-5'>  
                                    Instant, Reliable and cost-effective solution for Individual, Vendors and Businesses
                                </p>
                                <a href="#" className="btn btn-primary btn-lg mt-3 px-4 py-3">Get Started</a>
                            </div>
                            <div className="col-lg-6 col-md-6 sm-12 xs-12 float-start">
                                <img src={imgPeople} className='img-fluid rounded'></img>
                            </div>
                    </div>
                    <div
                        className="tab-pane row"
                        id="profile"
                        role="tabpanel"
                        aria-labelledby="profile-tab">
                            <div className="col-lg-6 col-md-6 sm-12 xs-12  p-3 float-start text-white p-5">
                                <h1 className='display-4 fw-bolder'>Empower Your Employees and Customers</h1>
                                <p className='lead mt-3 fs-5'>  
                                    Distribute bulk data and airtime to your team or customers effortlessly with megashare solutions.
                                </p>
                                <a href="#" className="btn btn-primary btn-lg mt-3 px-4 py-3">Get Started</a>
                            </div>
                            <div className="col-lg-6 col-md-6 sm-12 xs-12 float-start">
                                <img src={imgVendors} className='img-fluid rounded'></img>
                            </div>
                    </div>
                    <div
                        className="tab-pane row"
                        id="messages"
                        role="tabpanel"
                        aria-labelledby="messages-tab"
                    >
                            <div className="col-lg-6 col-md-6 sm-12 xs-12  p-3 float-start text-white p-5">
                                <h1 className='display-4 fw-bolder'>Grow Your Business with Our APIs</h1>
                                <p className='lead mt-3 fs-5'>  
                                    Integrate DemmyPay's robust API into your platform to vend data and airtime at scale.
                                </p>
                                <a href="#" className="btn btn-primary btn-lg mt-3 px-4 py-3">Get Started</a>
                            </div>
                            <div className="col-lg-6 col-md-6 sm-12 xs-12 float-start">
                                <img src={imgBusinesses} className='img-fluid rounded'></img>
                            </div>
                    </div>

                </div>

                    
            </div>
        </div>
        
    );
}  

export default FeaturesCards;
import React from 'react';

function Navbar(){
    return (
        <nav className="navbar navbar-expand-lg navbar-light py-2 sticky-top bg-white shadow-md">
            <div className="container">
                <a className="navbar-brand fw-bolder" href="#">Demmypay</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item mx-2">
                        <a className="nav-link active" aria-current="page" href="#">Home</a>
                    </li>
                    
                    <li className="nav-item dropdown mx-2">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Products
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a className="dropdown-item" href="#feature">Buy & Sell</a></li>
                            <li><a className="dropdown-item" href="#feature">Mega share</a></li>
                            <li><a className="dropdown-item" href="#feature">APIs</a></li>
                        </ul>
                    </li>
                    <li className="nav-item dropdown mx-2">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Company
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a className="dropdown-item" target='_blank' href="https://demmyglobal.com">About</a></li>
                            <li><a className="dropdown-item" href="#faq">FAQ</a></li>
                            <li><a className="dropdown-item" href="#contact">Contact</a></li>
                            
                            <li><a className="dropdown-item" target='_blank' href="https://blog.demmypay.com">News</a></li>
                        </ul>
                    </li>
                    {/* <li className="nav-item mx-2">
                        <a className="nav-link" href="#" tabindex="-1">Pricing</a>
                    </li> */}
                </ul>
                
                    
                    <a className="nav-link text-dark" href="#" tabindex="-1">Login</a>
                    <a className="btn btn-primary" type="submit">Signup</a>
            
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Companies from './components/Companies';
import Features from './components/Features';
import Footer from './components/Footer';
import FeaturesCards from './components/FeaturesCards';
import Faq from './components/Faq';


function App(){
  return(
    <>
      <Navbar/>
      <Hero/>
      <Companies/>
      <FeaturesCards/>
      <Features/>
      <Faq/>
      <Footer/>
    </>
  
  );
}

export default App;
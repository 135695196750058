import React from 'react';
var img = './img/persons-removebg-preview.png';

function Faq(){

    return (

        <div className="container text-center mt-5 mb-5" id='faq'>
            <h2 className="fw-bold">Frequently Asked Questions</h2>
            <p className="lead mt-3 fs-5">Get answers to common questions about Demmypay</p>
            <div className="row mt-5">
                <div class="col-lg-8 col-md-8 col-sm-12 accordion accordion-flush mx-auto" id="accordionFlushExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="true"
                                aria-controls="flush-collapseOne"
                            >
                                What services does your platform provide?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseOne"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div class="accordion-body text-start">
                                Our platform offers airtime and data vending services, including direct purchases, bulk purchases, and API integration for organizations
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingTwo">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo"
                                aria-expanded="true"
                                aria-controls="flush-collapseTwo"
                            >
                                How do I create an account? 
                            </button>
                        </h2>
                        <div
                            id="flush-collapseTwo"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingTwo"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div class="accordion-body text-start">
                                Visit our platform at <a href="https://demmypay.com">www.demmypay.com</a>, click "Sign Up," and follow the instructions to create your account.
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseThree"
                                aria-expanded="true"
                                aria-controls="flush-collapseThree"
                            >
                                Is there a mobile app for your services? 
                            </button>
                        </h2>
                        <div
                            id="flush-collapseThree"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div class="accordion-body float-start">
                                Our mobile platforms are under development, however, our web app is mobile-compatible.
                            </div>
                        </div>
                    </div>
                    
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingFour">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFour"
                                aria-expanded="true"
                                aria-controls="flush-collapseFour"
                            >
                                What payment methods do you accept? 
                            </button>
                        </h2>
                        <div
                            id="flush-collapseFour"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingFour"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div class="accordion-body float-start">
                            We accept bank transfers, credit/debit cards, and other online payment gateways.
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingFive">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFive"
                                aria-expanded="true"
                                aria-controls="flush-collapseFive"
                            >
                                How secure is my personal and financial information?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseFive"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingFive"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div class="accordion-body float-start">
                                We use industry-standard encryption to ensure the security of all user data and transactions.
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingSix">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseSix"
                                aria-expanded="true"
                                aria-controls="flush-collapseSix"
                            >
                                What should I do if I encounter an issue? 
                            </button>
                        </h2>
                        <div
                            id="flush-collapseSix"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingSix"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div class="accordion-body float-start">
                                You can click here to reach us directly on Whatsapp or email us at hello@demmypay.com.
                            </div>
                        </div>
                    </div>


                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingSeven">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseSeven"
                                aria-expanded="true"
                                aria-controls="flush-collapseSeven"
                            >
                                What happens if I try to create multiple accounts with the same email and name? 
                            </button>
                        </h2>
                        <div
                            id="flush-collapseSeven"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingSeven"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div class="accordion-body text-start">
                                Our system does not allow multiple accounts using the same email and name. Please ensure each account is unique.
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingEight">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseEight"
                                aria-expanded="true"
                                aria-controls="flush-collapseEight"
                            >
                                What is required to complete a transaction? 
                            </button>
                        </h2>
                        <div
                            id="flush-collapseEight"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingEight"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div class="accordion-body text-start">
                                Customers must provide their BVN for verification, set transaction pin, and fund their wallets before performing transactions.
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingNine">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseNine"
                                aria-expanded="true"
                                aria-controls="flush-collapseNine"
                            >
                                What happens if a transaction fails but I am charged? 
                            </button>
                        </h2>
                        <div
                            id="flush-collapseNine"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingNine"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div class="accordion-body text-start">
                                All failed transactions that are charged will be automatically refunded to your wallet. If a refund is not made within 48 hours, please contact our support team with the transaction details. Refunds are processed within 48 hours.
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>


            <div class="row mt-5 py-5 mt-5 bg-light rounded" id='contact'>
                <div class="col-lg-2 col-md-2 col-sm-4 mx-auto">
                    <img src={img}/>
                </div>
                <h5 className='fw-bold mt-3'>Still have questions</h5>
                <p className='fs-5'>
                    Can't find the answer you are looking for? You can chat directly with our friendly team
                </p>
                <div class="col-lg-2 col-md-3 col-sm-3 mx-auto">
                    <a href='https://wa.me/2349064000028' target='_blank' className="btn btn-primary mt-3 px-4 py-3"><i className='bi bi-whatsapp'></i> Get in touch</a>
                </div>
                
            </div>
            
        </div>

    );

}

export default Faq;